
import RadioButtonGroup from "@/components/FormBuilder/Components/RadioButtonGroup.vue";
import { stringifyAddress } from "@/helpers/stringifyAddress";
import { capitalize, uniq } from "lodash";
import Vue from "vue";

interface IListUsersData {
  loading: boolean;
  county: string;
  changingCounty: boolean;
}

export default Vue.extend({
  components: { RadioButtonGroup },
  name: "address-scrubbing-success-modal",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    addressScrubbingResponse: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data(): IListUsersData {
    return {
      loading: false,
      county: "",
      changingCounty: false
    };
  },
  methods: {
    showCountyOptions(): void {
      this.changingCounty = true;
    },
    submitHandler(): void {
      this.$emit("success", this.county);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1250);
    },
    capitalizeFirstLetter(str: string): string {
      if (str && str.includes(" ")) {
        const words = str.split(" ");
        return words
          .map(word => {
            return capitalize(word);
          })
          .join(" ");
      }
      return capitalize(str);
    }
  },
  watch: {
    addressScrubbingResponse: {
      deep: true,
      immediate: true,
      handler(val: any) {
        if (val.scrubbedAddress) {
          this.county =
            val.scrubbedAddress.county || val.originalAddress.county;
        }
      }
    },
    show: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("riskAddressSuccessModal");
        } else {
          this.$modal.hide("riskAddressSuccessModal");
        }
      }
    }
  },
  computed: {
    formattedAddress(): string {
      const {
        scrubbedAddress,
        originalAddress
      } = this.addressScrubbingResponse;
      const joinedAddress = {
        ...scrubbedAddress,
        ...originalAddress
      };
      return stringifyAddress(joinedAddress);
    },
    formattedAddressEntered(): string {
      const { originalAddress } = this.addressScrubbingResponse;
      return stringifyAddress(originalAddress || {}) || "";
    },
    formattedAddressScrubbed(): string {
      const { scrubbedAddress } = this.addressScrubbingResponse;
      return stringifyAddress(scrubbedAddress || {}) || "";
    },
    countyOptions(): any[] {
      const { countyMatches = [] } = this.addressScrubbingResponse;
      const uniqueCountyMatches = uniq(
        countyMatches.map((x: any) => x.countyName)
      );
      if (uniqueCountyMatches.length > 0) {
        return uniqueCountyMatches.map((match: any) => ({
          label: match,
          value: this.capitalizeFirstLetter(match)
        }));
      }
      return [];
    }
  }
});
