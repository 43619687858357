
import { stringifyAddress } from "@/helpers/stringifyAddress";
import SelectSearchCounties from "@/components/FormFields/SelectSearchGbsHelps.vue";
import Vue from "vue";

interface IListUsersData {
  loading: boolean;
  county: string;
}

export default Vue.extend({
  name: "address-scrubbing-failed-modal",
  components: { SelectSearchCounties },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    riskAddress: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data(): IListUsersData {
    return {
      loading: false,
      county: ""
    };
  },
  methods: {
    submitHandler(): void {
      this.$emit("success", this.county);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1250);
    }
  },
  computed: {
    formattedAddress(): string {
      return stringifyAddress(this.riskAddress as any);
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("riskAddressFailedModal");
        } else {
          this.$modal.hide("riskAddressFailedModal");
        }
      }
    }
  }
});
