import { quoteApplicantName } from "./quoteApplicantName";
import Vue from "vue";
const { __getText } = Vue.prototype;
import {
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import { isValidEffectiveDate } from "@/helpers/validateEffectiveDate";
import { IUserModel } from "@/types";

export const distanceToFireHydrantOptions = [
  { label: "1-500", value: 500 },
  { label: "501-1000", value: 1000 },
  { label: "1001-1500", value: 1500 },
  { label: "1501 and greater", value: 1500 },
  { label: "Alternate Water source", value: 0 },
  { label: "None", value: 10000 }
];

export const distanceToCoastOptions = [
  { label: "0.00 - 0.49", value: 0.49 },
  { label: "0.5", value: 0.5 },
  { label: "0.51 - 0.99", value: 0.99 },
  { label: "1.00", value: 1.0 },
  { label: "1.01 - 1.99", value: 1.99 },
  { label: "2.00", value: 2.0 },
  { label: "2.01 - 2.99", value: 2.99 },
  { label: "3.00", value: 3.0 },
  { label: "3.01 - 3.99", value: 3.99 },
  { label: "4.00", value: 4.0 },
  { label: "4.01 - 4.99", value: 4.99 },
  { label: "5.00 or greater", value: 5.0 }
];

export default function(user: IUserModel, createdOn: Date) {
  return [
    ...quoteApplicantName("quoteApplication.applicant"),
    ...useFormFieldGroup(
      [
        useField({
          key: "effectiveDate",
          label: "Effective Date",
          required: true,
          type: "date",
          pickerOptions: {
            disabledDate: (fieldValue: Date) =>
              !isValidEffectiveDate(fieldValue, createdOn, user)
          }
        }),
        useSelectField({
          key: "distantToFireHydrantFt",
          label: "Distance to Fire Hydrant (ft)",
          options: distanceToFireHydrantOptions,
          required: true
        }),
        useTextField({
          key: "distantToFireStationMi",
          label: "Distance to Fire Station (mi)",
          placeholder: "Please enter distance",
          required: true,
          validations: [
            {
              method: "integer",
              error: __getText("fields", "numberError")
            },
            {
              method: "maxLength",
              value: 3,
              error: __getText(
                "fields",
                "distanceToFireStationLengthValidateError"
              )
            }
          ]
        })
      ],
      { layout: "3-col" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "distanceToCoast",
          label: "Miles To Coast",
          options: distanceToCoastOptions,
          required: true,
          clearable: true,
          filterable: true,
          information: {
            title: "Distance to Coast",
            body: `Measurements are determined by using Google earth <br>
              or contact your Underwriter for assistance with measurements.<br>
              Major bodies of salt water are included in your underwriting guidelines.`
          }
        })
      ],
      { layout: "3-col" }
    ),
    useField({
      key: "riskAddress",
      label: "Risk Address",
      required: true,
      type: "address",
      addressType: "physicalAddress",
      validateAddress: false,
      disableState: true
    }),
    useField({
      key: "mailingAddress",
      label: "Mailing Address",
      required: true,
      type: "address",
      allowSyncingWithPhysicalAddress: true,
      isPOBOXMailingAddress: "true",
      addressType: "mailingAddress",
      validateAddress: false,
      disableState: false,
      limitToTexas: false
    })
  ];
}
