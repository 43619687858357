var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"topActionButtonPrimary":{
    text: 'Validate Address',
    key: 'validate',
    loading: _vm.makingApiRequest,
    disabled: !_vm.canValidateAddress
  },"topActionButtonSecondary":_vm.hasValidRiskAddress && _vm.canProceed
      ? {
          text: 'Next',
          key: 'next'
        }
      : undefined,"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('custom-alert',{attrs:{"title":"An Error Occuured","show":_vm.genericErrorMessage.length > 0,"dismissible":true,"type":"danger"}},[_c('p',[_vm._v(" "+_vm._s(_vm.genericErrorMessage)+" ")])]),_c('div',{staticClass:"mb-24"},[_c('UnderwriterInfo')],1),_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.quoteRiskAddressForm(_vm.$getCurrentUser, this.createdOn),"formValues":_vm.riskAddressData,"disabled":_vm.quoteHasBeenSubmitted || _vm.quoteHasBeenDeleted},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":function($event){_vm.validation = $event}}}),_c('risk-address-scrubbing-failed-modal',{attrs:{"riskAddress":_vm.riskAddressData.riskAddress,"show":_vm.showAddressValidationFailedModal},on:{"success":_vm.saveAddressAndCountyData,"close":function($event){_vm.showAddressValidationFailedModal = $event}}}),_c('risk-address-scrubbing-success-modal',{attrs:{"addressScrubbingResponse":_vm.addressScrubbingResponse,"show":_vm.showAddressValidationSuccessModal},on:{"close":function($event){_vm.showAddressValidationSuccessModal = $event},"success":_vm.saveAddressAndCountyData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }